import React, {useEffect, useState} from "react";
import {  Tab, } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import {  useDispatch } from "react-redux";
import * as actions from "../_redux/incidents/Actions";
import GoogleMapReact from 'google-map-react';
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import config from "../../../../config"
import Lists from "./list";



export function Incidents(props) {

    const dispatch = useDispatch();
    const [key, setKey] = useState("active");
  
   
    const { org, active_danger, pending_danger } = useSelector(
      (state) => ({
          org: state.auth.organization,
          active_danger: state.alert.active_danger,
          pending_danger: state.alert.pending_danger,
      }),
      shallowEqual
  );


    const tabs = [
        {
            "name": "Pending", "key": 'pending'
        },
        {
            "name": "Active", "key": 'active'
        }
    ]


    useEffect(() => {
      loadData()
    }, [])

    function loadData() {
      dispatch(actions.fetchAllData('pending_danger', org.business._id ));
      dispatch(actions.fetchAllData('active_danger', org.business._id ));
    }

  
    return (<>

       
            <span className="text-dark font-weight-bold ml-5 mr-">
              Emergency Alerts
            </span>
      
            <div style = {{padding: 20, width: '100%'}}>
                <div style = {{
                    border: '0.886598px solid #F0EDFF', 
                    paddingVertical: 20,
                    flexDirection: 'row',
                    display: 'flex',
                    height: 86,
                    width: '100%'
                }}>
                  {/* className = "text-center"  */}
                    <div style = {{display: 'flex',  justifyContent: 'center', alignItems: 'center', flex: 1, borderRight: '0.886598px solid #F0EDFF'}}>
                    <SVG style = {{width: 32, height: 32, marginRight: 5}} src={toAbsoluteUrl('/media/nav/users.svg')}/>
                    <div>
                        <p style = {{fontSize: 10, marginBottom: 0}}>Pending</p>
                        <h2 style = {{fontSize: 14, fontWeight: 600}}>{pending_danger && pending_danger.meta ? pending_danger.meta.total_items : '...'}</h2>
                    </div>
                    </div>

                    <div style = {{display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1}}>
                    <SVG style = {{width: 32, height: 32, marginRight: 5}} src={toAbsoluteUrl('/media/nav/users.svg')}/>
                    <div>
                        <p style = {{fontSize: 10, marginBottom: 0}}>Active</p>
                        <h2 style = {{fontSize: 14, fontWeight: 600}}>{active_danger && active_danger.meta ? active_danger.meta.total_items : '...'}</h2>
                    </div>
                    </div>
                </div>
            </div>


            <div style = {{padding: 20}}>

            <Tab.Container defaultActiveKey={key}>
                <ul class="nav nav-tabs nav-bold nav-tabs-line">
                 {tabs.map((ele, i) => {
                    return (
                    <li 
                    key = {i}
                    class="nav-item">
                        <a class={`nav-link ${key === ele.key ? 'active' : ''}`} style = {{fontSize: 14}} data-toggle="tab"
                        onClick={() => setKey(ele.key)}
                        href="javascript:;">{ele.name}</a>
                    </li>
                    )
                })}
                </ul>

                <div className="p-4">
                
                    
                      <Lists
                      action = {() => loadData()}
                      history = {props.history}
                      type = {key}/>
                  </div>
               
                </Tab.Container>


         
            </div>





    

</>
);
}
