// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../_metronic/_helpers";
import * as uiHelpers from "../UIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../_metronic/_partials/controls";
import { useCustomersUIContext } from "../UIContext";
import moment from "moment";
import * as actions from "../../_redux/incidents/Actions";


export function Table() {
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      setIds: customersUIContext.setIds,
      queryParams: customersUIContext.queryParams,
      setQueryParams: customersUIContext.setQueryParams,
      openEditCustomerDialog: customersUIContext.openEditCustomerDialog,
      openDeleteCustomerDialog: customersUIContext.openDeleteCustomerDialog,
      enterData: customersUIContext.enterData
    };
  }, [customersUIContext]);

  const dispatch = useDispatch();
 
  const { org, currentState } = useSelector(
    (state) => ({ 
      org: state.auth.organization,
      currentState: state.alert }),
    shallowEqual
  );

  
  const { totalCount, entities, listLoading } = currentState;

 
  
   
  useEffect(() => {
    // customersUIProps.setIds([]);
     
    var query = '?page=1';
    var params = customersUIProps.queryParams;
    // console.log("paramsoooo", params);

    // return;
    if (params) {
        var skip = (params.pageNumber - 1);
        if (skip > 0) {
            // skip = skip * 10;
            skip = skip + 1;
        } else {
          skip = 1;
        }
        query = '?page='+skip+'&limit='+params.pageSize;


        if (params.filter) {
          if (params.filter.status) {
            query+="&status="+params.filter.status;
          }
          if (params.filter.class) {
            query+="&class="+params.filter.class;
          }
          if (params.filter.range) {
            query+="&start_date="+params.filter.range.startDate+"&end_date="+params.filter.range.endDate;
          }
          if (params.filter.reporter) {
            query+="&custom="+JSON.stringify({reporter: params.filter.reporter});
          }
          
        }
    }

    dispatch(actions.fetchAllData('incident_data', org.business._id, query));
    // dispatch(actions.fetchAllData('incident_data', org.business._id ));
  
  }, [customersUIProps.queryParams, dispatch, org.business._id])


  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function truncate(str, num = 10) {
      return str.length > num ? str.substring(0, num - 3) + "..." : str;
  }

  const columns = [
    {
      dataField: "name",
      text: "Incident Location",
      formatter: (data, full) => {
        return `${truncate(full.input_address ? full.input_address : full.addresses[0].formatted_address, 20)}`;
      }
    },
    {
      dataField: "name",
      text: "Region",
      formatter: (data, full) => {
        return `${truncate(full.lga)}`;
      }
    },
    {
      dataField: "date",
      text: "Time of activation",
      sort: false,
      // sortCaret: sortCaret,
      formatter: function (dates, row) {
          var date = new Date(row.created_at);
          return moment(date).format('MMMM Do YYYY, h:mm:ss a');
      }
    },
    {
      dataField: "name",
      text: "Requester's Name",
      formatter: (data, full) => {
        return `${capitalizeFirstLetter(full.user ? full.user.first_name : '')} ${capitalizeFirstLetter(full.user ? full.user.last_name : '')}`;
      }
    },
    {
      dataField: "phone number",
      text: "Phone Number",
      formatter: (data, full) => {
        return `${full.user ? full.user.phone_number : ''}`;
      }
    },
    {
      dataField: "incident",
      text: "Incident Category",
      // headerSortingClasses
      formatter: (data, full) => {
        return `${full.category ? full.category.name : 'TBD'}`;
      }
    },
    {
      dataField: "details",
      text: "Incident Details",
      formatter: (data, full) => {
        return `${full.meta_data && full.meta_data.more_info ? full.meta_data.more_info: 'NULL'}`;
      }
    },
    {
      dataField: "category_class",
      text: "Incident Class",
      // sort: true,
      // sortCaret: sortCaret,
      // headerSortingClasses,
      // formatter: (data, full) => {
      //   return `${full.category_class ? full.category_class : 'NULL'}`;
      // }
      sortCaret: sortCaret,
      formatter: columnFormatters.TypeColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.StatusColumnFormatter,
      headerSortingClasses,
    },
    {
        dataField: "date",
        text: "Response Time",
        sort: false,
        sortCaret: sortCaret,
        formatter: (data, full) => {
          return `${full.completed_at ? full.completed_at + ' min' : 'NULL'}`;
        }
      },
      // {
      //   dataField: "action",
      //   text: "Actions",
      //   formatter: columnFormatters.ActionsColumnFormatter,
      //   formatExtraData: {
      //     openEditCustomerDialog: customersUIProps.openEditCustomerDialog,
      //     openDeleteCustomerDialog: customersUIProps.openDeleteCustomerDialog,
      //     enterData: customersUIProps.enterData
      //   },
      //   classes: "text-right pr-0",
      //   headerClasses: "text-right pr-3",
      //   style: {
      //     minWidth: "100px",
      //   },
      // }
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: customersUIProps.queryParams.pageSize,
    page: customersUIProps.queryParams.pageNumber,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="_id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  customersUIProps.setQueryParams
                )}
                selectRow={getSelectRow({
                  entities,
                  ids: customersUIProps.ids,
                  setIds: customersUIProps.setIds,
                })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}


