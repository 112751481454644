import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/incidents/Actions";
import { Header } from "./header";
import * as account_actions from "../../../Agents/_redux/accounts/Actions";
import Lists from "./list";

export function Assign({ id, show, onHide }) {
  
  const dispatch = useDispatch();
  const { actionsLoading, agents, org, error_ser } = useSelector(
    (state) => ({
      actionsLoading: state.accounts.actionsLoading || state.accounts.listLoading,
      org: state.auth.organization,
      agents: state.accounts.entities,
      error_ser: state.accounts.error
    }),
    shallowEqual
  );

  const [image, set_image] = useState(null)
  const [image_link, set_image_link] = useState(null)
  const [error, set_error] = useState(null)
  
 

  
  function handleChange(event) {
    set_image(event.target.files[0]);
    set_image_link(URL.createObjectURL(event.target.files[0]));
  }

  useEffect(() => {
    if (!agents || agents.length === 0) {
      dispatch(account_actions.fetchAllData('agents', org._id ));
    }
  }, [])


  useEffect(() => {
    if (error_ser) {
      set_error(error_ser);
    }
  }, [error_ser]);

  // server request for saving customer
  const saveCustomer = (e) => {

      set_error(null);

      if (!image) {
        set_error("Upload this agent's picture");return;
      }
 
      var body = {};

      

      body['first_name'] = e.target.elements.first_name.value;
      body['last_name'] = e.target.elements.last_name.value;
      body['email'] = e.target.elements.email.value;
      body['phone_number'] = e.target.elements.phone.value;
      body['password'] = e.target.elements.password.value;
      body['gender'] = "male";
      body['partner_id'] = org._id;
      body['country_code'] = 'NG';
      body['country_phone_code'] = '+234';
      body['user_type'] = 'agent';

      

      // console.log(body); 
      // return;
      

      dispatch(actions.addData('agent', body)).then((res) => {
        onHide()
        //upload picture...
        // console.log(res);
      }).catch(() => {
        set_error("An error occurred while creating this agent");return;
      });
  };

  return (
    <Modal
      size="sm"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Header id={id} />
      {/* <FormView
        saveCustomer={saveCustomer}
        actionsLoading={actionsLoading}
        data={null}
        onHide={onHide}
        error = {error}
        image_link = {image_link}
        image = {image}
        handleChange = {handleChange}
      /> */}

      <Lists data = {agents} 
      loading = {actionsLoading}
      onHide={onHide}/>
    </Modal>
  );
}
