import React, {useEffect, useState} from "react";
import {  Tab, } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import {  useDispatch } from "react-redux";
import * as actions from "../_redux/incidents/Actions";
import GoogleMapReact from 'google-map-react';
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import config from "../../../../config"
import ViewList from "./viewList";
import ViewAgent from "./viewAgent";
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getDistance } from 'geolib';
var Ably = require('ably');


export function View(props) {

    const dispatch = useDispatch();
    const [key, setKey] = useState("info");
    const [eta, set_eta] = useState(null);
    const [distance, set_distance] = useState(null);
    const [agent_loc, setAgentLocation] = useState(null);
    const [victim_loc, setVictimLocation] = useState(null);
    const [_markers, setMarkers] = useState([]);
    
    const { id } = useParams();
    const notify = (message) => toast(message)

   
    const { org, dataForView, ably, error } = useSelector(
      (state) => ({
          org: state.auth.organization,
          dataForView: state.alert.dataForView,
          error: state.alert.error,
          ably: state.alert.ably
      }),
      shallowEqual
  );



    const tabs = [
        {
            "name": "Info", "key": 'info'
        },
        {
            "name": "Agent", "key": 'agent'
        }
    ]


    useEffect(() => {
      loadData()
    }, [])


    useEffect(() => {
        if (dataForView) {
            var loc = {};
            loc['latitude'] = dataForView.location.latitude;
            loc['longitude'] = dataForView.location.longitude;
            loc['type'] = 'victim';
            setVictimLocation(loc);

            if (dataForView.agent) {
                dispatch(actions.fetchAllData('agent_loc', dataForView.agent.id )).then((data) => {
                    console.log("Agent's loc", data)
                    if (data && data.longitude && !agent_loc) {
                        var loc = {};
                        loc['latitude'] = data.latitude;
                        loc['longitude'] = data.longitude;
                        loc['type'] = 'agent';
                        setAgentLocation(loc);
                    }
                })
            }
        }
      }, [dataForView])




    function loadData() {
        if (!dataForView && id) {
            dispatch(actions.fetchAllData('single', id ))
        }
    }


    useEffect(() => {
        if (ably && dataForView) {
           
            var channel = ably.channels.get(config.ABLY_CHANNEL_NAME);
            channel.subscribe(id, function (message) {
                if(message && message.data) {
                    dispatch(actions.fetchAllData('single', id ))
                }
            });

            if (!dataForView.agent) return;
            console.log('jasjssddc')
            channel.subscribe(`agentsLocations-${dataForView.agent.id}`, function (message) {
                //message.data.location
                if (message && message.data) {
                    console.log('coord', message.data.location);
                    var loc = {};
                    loc['latitude'] = message.data.location.latitude;
                    loc['longitude'] = message.data.location.longitude;
                    loc['type'] = 'agent';
                    setAgentLocation(loc);
                }
             
            });
        } 
    }, [ably, dataForView])

    useEffect(() => {
        if (agent_loc && victim_loc && dataForView ) {
    
            var real_distance = getDistance(
                { latitude: agent_loc.latitude, longitude: agent_loc.longitude },
                { latitude: victim_loc.latitude, longitude: victim_loc.longitude }
            );
            var distance = "";
            if (real_distance > 1000) {
              distance = Math.round(real_distance/1000) + 'km away';
            } else {
              distance = Math.round(real_distance) + 'm away';
            }
    
            let speedIs10MetersPerMinute = agent_loc.speed ? agent_loc.speed : 50;
            let estimatedDriveTimeInMinutes = real_distance / speedIs10MetersPerMinute;
            var eta = `${dataForView.status === 'arrived' ? 'Has arrived' : `Arriving in ${Math.round(estimatedDriveTimeInMinutes)} mins`}`;
          
            set_distance(distance);
            set_eta(eta);
            
        }
      }, [agent_loc, victim_loc, dataForView, dispatch])
    
    

    useEffect(() => {
        if (error) {
            notify(error, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                theme: 'light',
                type: 'warning',
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }, [error])

  
    useEffect(() => {
        var marks = Object.assign([], _markers);
        
        if (agent_loc) {
        
         var index = marks.findIndex(x => x.type ==="agent");
          if (index !== -1) {
              marks[index] = agent_loc;
          } else {
                marks.push(agent_loc);
            }
        }
  
  
  
        if (victim_loc) {
       
          var index2 = marks.findIndex(x => x.type ==="victim");
          if (index2 !== -1) {
              marks[index2] = victim_loc;
          } else {
              marks.push(victim_loc);
          }
        }
        
  
        // if (dataForView) {
  
        //   var loc = {};
        //   loc['latitude'] = dataForView.location.coordinates[1];
        //   loc['longitude'] = dataForView.location.coordinates[0];
        //   loc['type'] = 'place';
        //   loc['name'] = dataForView.name;
          
       
        //   var index3 = marks.findIndex(x => x.type ==="place");
        //   if (index3 !== -1) {
        //       marks[index3] = loc;
        //   } else {
        //       marks.push(loc);
        //   }
        // }
  
  
        setMarkers(marks)
        dispatch(actions.fetchData(marks, 'm'))
        // console.log("HAHAHAHA", marks)
  
  
    }, [ agent_loc,victim_loc ])

    

    
    return (<>

            <a style = {{marginTop: 50, marginLeft: 15}} onClick = {() => {
                props.history.push('/incidents')
                dispatch(actions.fetchData(null))
                }}>
                <span className="text-dark font-weight-bold mt-10 mb-10">
                <SVG style = {{width: 18, height: 18, marginRight: 5}} src={toAbsoluteUrl('/media/nav/arrow-left.svg')}/>
                Back to requests
                </span>
            </a>
      
          


            <div style = {{padding: 20, marginTop: 0}}>

            <Tab.Container defaultActiveKey={key}>
                <ul class="nav nav-tabs nav-bold nav-tabs-line">
                 {tabs.map((ele, i) => {
                    return (
                    <li 
                    key = {i}
                    class="nav-item">
                        <a class={`nav-link ${key === ele.key ? 'active' : ''}`} style = {{fontSize: 14}} data-toggle="tab"
                        onClick={() => setKey(ele.key)}
                        href="javascript:;">{ele.name}</a>
                    </li>
                    )
                })}
                </ul>

                <div className="mt-10">
                
                    {key === 'info' ?
                      <ViewList
                      action = {() => loadData()}
                      history = {props.history}
                      eta = {eta}
                      distance = {distance}
                      type = {key}/>
                      :
                      <ViewAgent
                      action = {() => loadData()}
                      history = {props.history}
                      type = {key}/>
                    }

                  </div>
               
                </Tab.Container>


                {/* <ToastContainer/> */}
            </div>





    

</>
);
}
