import React, {Suspense, lazy, useEffect} from "react";
import {Redirect, Switch, Route} from "react-router-dom";
import {LayoutSplashScreen, ContentRoute} from "../_metronic/layout";
import { DashboardPage } from "./modules/Dashbord/pages";
import { IncidentPage } from "./modules/Incidents/pages";
import { ReportPage } from "./modules/Reports/pages";
import { shallowEqual, useSelector } from "react-redux";

const AgentsRoots = lazy(() =>
  import("./modules/Agents/pages")
);


const UserRoots = lazy(() =>
  import("./modules/User/pages")
);


const BillingRoots = lazy(() =>
  import("./modules/Billing/pages")
);

const SettingsRoot = lazy(() =>
  import("./modules/Settings/pages")
);

// const IncidentRoot = lazy(() =>
//   import("./modules/Incidents/pages")
// );

export default function BasePage() {
    // const clickRef = useRef();
    // const dispatch = useDispatch();

    const { ably } = useSelector(
      (state) => ({
          user: state.auth.user,
          org: state.auth.organization,
          ably: state.alert.ably
      }),
      shallowEqual
  );

  // const [ ,setOptions] = useState({});
  // const [ ,setTitle] = useState('');
  
  // const handleButtonClick = () => {
  //     if (!user) {
  //       return;
  //     }
  //     clickRef.current.click()
  //     setTitle('New Request')
  //     setOptions({
  //       tag: Date.now(),
  //       body: `${user.first_name} attend to this new request`
  //     })
  // }

  useEffect(() => {
    if (!ably) {
      // setupReal()
    }
  }, [ably])

  // function setupReal() {
  //     var client = new Ably.Realtime(config.ABLY_KEY);
  //     dispatch(actions.sendAb(client));
  //     client.connection.on('connected', function() {
  //         var channel = client.channels.get(config.ABLY_CHANNEL_NAME);
  //         channel.subscribe(user.user_id, function (message) {
  //             // dispatch(actions.fetchAllData('active_danger', user.user_id ));
  //             dispatch(actions.fetchAllData('pending_danger', org.business._id ));
  //             dispatch(actions.fetchAllData('active_danger', org.business._id ));
  //             handleButtonClick();
  //         });
  //     });
  // }



    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
           
            <Switch>
              
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/dashboard"/>
                }
                <ContentRoute path="/dashboard" component={DashboardPage}/>
              
                <Route path="/incidents" component={IncidentPage}/>
                <Route path="/reports" component={ReportPage}/>
                <Route path="/users" component={UserRoots}/>
                <Route path="/customers" component={AgentsRoots}/>
                <Route path="/settings" component={SettingsRoot}/>
                <Route path="/billings" component={BillingRoots}/>
                
                <Redirect to="error/error-v1"/>
            </Switch>

            
        </Suspense>
    );
}
