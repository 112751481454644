import axios from "axios";
import config from "../../../../../config";

export const URL = config.URL;

// CREATE =>  POST: add a new data to the server
export function addNewData(type, data, params) {
  if (type === 'agent') {
    return axios.post(URL+'signup', data);
  } else if (type === 'user') {
    return axios.post(URL+'businesses/'+params+'/add-user', data);
  } else if (type === 'remove') {
    return axios.post(URL+'businesses/'+params+'/remove-user', data);
  } else if (type === 'file') {
    return axios({
      method: "POST",
      url: URL+'uploads',
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
    
  }
  return axios.post(URL+'auth/user/admin', data);
}

// READ
export function getAllDatas() {
  return axios.get(URL);
}

export function getSataById(dataId) {
  return axios.get(`${URL}/${dataId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findDatas(type, queryParams) {
  if (type === 'agents') {
    return axios.get(`${URL}businesses/${queryParams}/users`);
  } else if (type === 'single') {
    return axios.get(`${URL}user/${queryParams}`);
  } else if (type === 'user') {
    return axios.get(`${URL}users/phone-number-email${queryParams}`);
  } else if (type === 'tasks') {
    return axios.get(`${URL}agents/${queryParams}/dangers?status=completed`);
  }
  return axios.get(`${URL}partners/${queryParams}/agents`);

}

export function inviteCustomer(payload) {
  // console.log('dddddddd:;:', payload);
  return axios.post(`${URL}businesses/${payload.businessId}/invite-customer`, payload);
}

// UPDATE => PUT: update the data on the server
export function updateData(data) {
  return axios.put(`${URL}/${data.id}`, { data });
}

// UPDATE Status
export function updateStatusForDatas(ids, status) {
  return axios.post(`${URL}/updateStatusFordatas`, {
    ids,
    status
  });
}

// DELETE => delete the data from the server
export function deleteData(dataId) {
  return axios.delete(`${URL}/${dataId}`);
}

// DELETE datas by ids
export function deleteDatas(ids) {
  return axios.post(`${URL}/deletedatas`, { ids });
}
