import React,  {useEffect, useState} from "react";
import { Route, Switch } from "react-router-dom";
import { CustomersLoadingDialog } from "./loading-dialog";
import {Incidents} from "./main";
import {View} from "./view";
import {Assign} from './assign';
import { CustomersUIProvider } from "./UIContext";
import { shallowEqual, useSelector } from "react-redux";
import {  useDispatch } from "react-redux";
import * as actions from "../_redux/incidents/Actions";
import GoogleMapReact from 'google-map-react';
import config from "../../../../config"
import Marker from "../../../../_metronic/layout/components/marker";
import Notification from 'react-web-notification';
import useSound from 'use-sound';
import boopSfx from '../../../../sound/sound.mp3';

import { useParams } from 'react-router-dom';

var Ably = require('ably');


export function IncidentPage({ history }) {
  const customersUIEvents = {

  }
  const { id } = useParams();
  

  const dispatch = useDispatch();

 
  const { dataForView, org, _markers, entities } = useSelector(
    (state) => ({
      dataForView: state.alert.dataForView,
      org: state.auth.organization,
      _markers: state.alert.markers,
      entities: state.alert.active_danger ? state.alert.active_danger.data : []
    }),
    shallowEqual
);
  const [coord, setCoord] = useState({
    lat: 6.5244,
    lng: 3.3792
  });
  const [zoom, setZoom] = useState(10);



  const tabs = [
      {
          "name": "Pending", "key": 'pending'
      },
      {
          "name": "Active", "key": 'active'
      }
  ]


  useEffect(() => {
    loadData()
  }, [])

  function loadData() {
    // dispatch(actions.fetchAllData('pending_danger', org._id ));
    // dispatch(actions.fetchAllData('active_danger', org._id ));
  }

  
  useEffect(() => {
    if (dataForView) {
      setTimeout(function(){ 
          setCoord({
              lat: dataForView.location.latitude,
              lng: dataForView.location.longitude
          })
      }, 500);

      setTimeout(function(){ 
          setZoom(25); 
      }, 800);
   } else {
      setTimeout(function(){ 
          setZoom(11); 
      }, 500);
    }
  }, [dataForView])


  function navTo(ele) {
    setCoord({
        lat: ele.location.latitude,
        lng: ele.location.longitude
    })
    setTimeout(function(){ 
        setZoom(18); 
    }, 1000);

    history.push(`/incidents/view/${ele._id}`);
    dispatch(actions.fetchData(ele))
  }

  

  
//   function setupReal() {
//     var client = new Ably.Realtime(config.ABLY_KEY);
//     dispatch(actions.sendAb(client));
//     client.connection.on('connected', function() {
//         var channel = client.channels.get(config.ABLY_CHANNEL_NAME);
//         channel.subscribe(user.user_id, function (message) {
//             // console.log("ABLY!!!", message.name, message.data)
//             dispatch(actions.fetchAllData('active_danger', user.user_id ));
//             handleButtonClick();
//         });
//     });
// }


  // console.log(history)
  function _onChange(data) {
    setCoord({
        lat: data.center.lat,
        lng: data.center.lng
    })
    setZoom(data.zoom); 
  }
  
  return (
    <CustomersUIProvider customersUIEvents={customersUIEvents}>
      <CustomersLoadingDialog />

      <div style = {{
            height: '100%', 
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            // marginRight: -25,
            // marginLeft: -25,
            // marginTop: -25,
        }}>
          

            <div style = {{
                // flex: 2,
                width: 350,
                display: 'flex',
                flexDirection: 'column'
            }}>
      
            <Switch>


              <Route path="/incidents" exact>
                {({ history, match }) => (
                  <Incidents
                    show={match != null}
                    history = {history}
                    onHide={() => {
                      history.push("/");
                    }}
                  />
                )}
              </Route>

              <Route path="/incidents/view/:id">
                {({ history, match }) => (
                  <View
                    show={match != null}
                    history = {history}
                    onHide={() => {
                      history.push("/");
                    }}
                  />
                )}
              </Route>


            </Switch>
      
        
            <Route path="/incidents/view/:id/assign">
              {({ history, match }) => (
                <Assign
                  show={match != null}
                  id={match && match.params.id}
                  history = {history}
                  onHide={() => {
                    history.goBack();
                  }}
                />
              )}
            </Route>


            </div>

            <div style = {{
                flex: 5,
                display: 'flex',
                backgroundColor: 'grey'
            }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: config.google_map_api_key }}
                defaultCenter={{
                    lat: 6.5244,
                    lng: 3.3792
                }}
                defaultZoom={11}
                center = {coord}
                zoom={zoom}
                onChange={_onChange}
                options={{
                    styles: config.mapStyle,
                }}
                id="map"
            >

              {
                 !dataForView ?
            
                 entities.map((ele, i) => {
                     return (
                         <div key = {i}
                             lat={ele.location.latitude}
                             lng={ele.location.longitude}
                             text={ele.category ? ele.category.name : 'Danger'}
                         >
                             <Marker
                                 onClick = {() => navTo(ele)}
                                 data = {ele}
                                 id = {id ? id : 'none'}
                             />
                          </div>
                     )
                 })
             
                 :
     
                 _markers.map((ele, i) => {
                  //  console.log("dsdcascsdcasdcsdcsdcs", ele)
                     return (
                         <div key = {i}
                             lat={ele.latitude}
                             lng={ele.longitude}
                             text={'ele.name'}
                         >
                             <Marker
                                 onClick = {() => console.log(ele)}
                                 data = {ele}
                                 id = {id ? id : 'none'}
                             />
                          </div>
                     )
                 })
     
              }

            </GoogleMapReact>

            </div>

       

    
        </div>
      
    </CustomersUIProvider>
  );
}
