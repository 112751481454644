
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import BounceLoader from "react-spinners/BounceLoader";
import { css } from "@emotion/react";

const Wrapper = styled.div`
  position: absolute;
  width: 18px;
  height: 18px;
  background-color: #000;
  border: 2px solid #fff;
  border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%);
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  &:hover {
    z-index: 1;
  }
`;

const override = css`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  user-select: none;
  transform: translate(-50%, -50%);
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  &:hover {
    z-index: 1;
  }
`;

function getColor(d) {
    // if (d === 'active') {
    //   return 'rgba(73, 60, 171, 1)';
    // } else if (d === 'pending') {
    //   return 'rgba(184, 0, 44, 1)';
    // } else if (d === 'victim') {
    //   return 'rgba(184, 0, 44, 1)';
    // } else {
    //   return 'rgba(50, 88, 168, 1)';
    // }

    return 'rgba(184, 0, 44, 1)';
   
  }


const Marker = ({ text, onClick, data, id }) => (
    <div>
       
        <div style = {{
            paddingInline: 10,
            paddingBlock: 5,

            position: 'absolute',
            alignSelf: 'center',
            top: -50,
            // left: -35,
            left: '50%',
            // right: 0,

            // marginBottom: 20,

            // position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, 
            justifyContent: 'center', alignItems: 'center',

            border: '1px solid #FFF',
            borderRadius: 5,
            backgroundColor: '#FFF'
        }}>
            <div style = {{fontSize: 12, color: getColor(data.status), flexShrink: 1}} class = "font-weight-bold mt-1 text-dark-75 text-center">{data.category ? data.category.name : 'Danger'}</div>
        </div> 
        {data.name ?
          <Wrapper
                alt={text}
                style = {{backgroundColor: getColor(data.status)}}
                onClick={onClick}
            />
            : 
          <BounceLoader css={override} size={18} color = {getColor(data.type)}/>
        }
    </div>

);

Marker.defaultProps = {
  onClick: null,
};

Marker.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
};

export default Marker;