import * as requestFromServer from "./Crud";
import {DashboardSlice, callTypes} from "./Slice";

const {actions} = DashboardSlice;


export const fetchAllData = (type, queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findDatas(type, queryParams)
    .then(response => {
      var res = response.data;
      // console.log("Server response is", response.data);
      if (type === 'dashboard_data') {
        dispatch(actions.dashboardData(res.data));
      } else if (type === 'users') {
        // console.log("userssss", res)
        dispatch(actions.accounts(res.data));
        // dispatch(actions.accountsMeta(res.data.meta ? res.data.meta : null));
      } else if (type === 'api') {
        dispatch(actions.api(res.data));
      } else if (type === 'pending') {
        dispatch(actions.pendingDa(res.data));
      } 
   })
    .catch(error => {
      error.clientMessage = "Can't find Datas";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchData = id => dispatch => {
  if (!id) {
    return dispatch(actions.DataFetched({ DataForEdit: undefined }));
  }

};

export const deleteData = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteData(id)
    .then(response => {
      dispatch(actions.DataDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete Data";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const addData = (type, DataForCreation, id) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .addNewData(type, DataForCreation, id)
    .then(response => {
      // const { Data } = response.data;
      dispatch(actions.disableLoad());

      // if (type ===)
      return response;
      // dispatch(actions.DataCreated({ Data }));
    })
    .catch(error => {
      error.clientMessage = "Can't create Data";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateData = Data => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateData(Data)
    .then(() => {
      dispatch(actions.DataUpdated({ Data }));
    })
    .catch(error => {
      error.clientMessage = "Can't update Data";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateDatasStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForDatas(ids, status)
    .then(() => {
      dispatch(actions.DatasStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update Datas status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteDatas = (ids, type) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteDatas(ids, type)
    .then((res) => {
      dispatch(actions.disableLoad());
      return res;
      // dispatch(actions.DatasDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete Datas";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
