import React from "react";
import { Route, Switch } from "react-router-dom";
import { CustomersLoadingDialog } from "./loading-dialog";
import {Report} from "./main";
import {View} from "./view";
import { CustomersUIProvider } from "../../Incidents/pages/UIContext";

export function ReportPage({ history }) {
  const customersUIEvents = {
    enterData: (id) => {
      history.push(`/incidents/${id}/view`);
    },
  }
 

  return (
    <CustomersUIProvider customersUIEvents={customersUIEvents}>
      <CustomersLoadingDialog />
      <Switch>
        <Route path="/">
          {({ history, match }) => (
            <Report
              show={match != null}
              history = {history}
              onHide={() => {
                history.push("/");
              }}
            />
          )}
        </Route>
        {/* <Route path="/incidents/:id/view">
          {({ history, match }) => (
            <View
              show={match != null}
              history = {history}
              onHide={() => {
                history.push("/");
              }}
            />
          )}
        </Route> */}
      </Switch>
      
    </CustomersUIProvider>
  );
}
