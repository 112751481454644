import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { send_pin, login } from "../_redux/authCrud";


const initialValues = {
  email: "",
  password: "",
};

// const search = window.location.search;
// const params = new URLSearchParams(search);
// const email = params.get('email');
// const type = params.get('type');

function Email_Activate(props) {

  // console.log(props.location.state);


  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const LoginSchema = Yup.object().shape({
    token: Yup.string()
      .min(6, "Minimum 6 symbols")
      .max(6, "Maximum 6 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };



  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {

      enableLoading();

      var body = {};
      body['code'] = values.token;
      body['email'] = props.location.state.email;
      send_pin(body)
        .then((response) => {
          disableLoading();
          setSubmitting(false);
          var data = response.data;

          if (data.status === 'Success') {
            var boddy = {};
            boddy['email'] = props.location.state.email;
            boddy['password'] = props.location.state.password;
            login(boddy)
              .then((responsed) => {
                var dacta = responsed.data;
                if (dacta.status === 'Success') {
                  props.login(dacta.data.access_token);
                }
              })
          } else {
            setLoading(false);
            setStatus(data.error)
          }
        })
        .catch((error) => {
          disableLoading();
          var message = error.response.data.message;
          // var status_code = error.response.status;
          setStatus(`${message}`);
        });
    },





  });



  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          {/* <FormattedMessage id="AUTH.LOGIN.TITLE" /> */}
          Verify Phone Number
        </h3>
        <p className="text-muted font-weight-bold">
          Enter token sent to your phone number
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
          null
        )}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="token"
            // type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "token"
            )}`}
            name="token"
            {...formik.getFieldProps("token")}
          />
          {formik.touched.token && formik.errors.token ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.token}</div>
            </div>
          ) : null}
        </div>




        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          {/* <Link
            to="/auth/login"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            Back to Login
          </Link> */}
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn font-weight-bold px-9 py-4 my-3`}
          >
            <span>Resend code</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Enter</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Email_Activate));
