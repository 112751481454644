/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
// import Icon from '@mui/material/Icon';

// import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
// import ThreeDRotation from '@mui/icons-material/ThreeDRotation';

// import { shallowEqual, useSelector } from "react-redux";


export function AsideMenuList({ layoutProps }) {

  //   const { org } = useSelector(
  //     (state) => ({
  //         org: state.auth.organization
  //     }),
  //     shallowEqual
  // );


  const location = useLocation();
  // const getMenuItemActive = (url, hasSubmenu = false) => {
  //   return checkIsActive(location, url)
  //     ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
  //     : "";
  // };

  const returnAct = (url) => {
    return checkIsActive(location, url)
      ? true
      : false;
  };


  const [links] = useState([
    {
      name: 'Overview',
      link: '/dashboard'
    },
    {
      name: 'Incidents',
      link: '/incidents'
    },
    {
      name: 'Reports',
      link: '/reports'
    },
    {
      name: 'Customers',
      link: '/customers'
    },
    // {
    //   name: 'Billing',
    //   link: '/billings'
    // },
    {
      name: 'Settings',
      link: '/settings'
    },
    {
      name: 'API Documentation',
      link: '/doc'
    }
  ])

  function getRoute(name, active) {
    if (name === 'Overview') {
      if (active) {
        return "/media/nav/dashboard_active.svg";
      } else {
        return "/media/nav/dashboard.svg";
      }
    } else if (name === 'Incidents') {
      if (active) {
        return "/media/nav/alert_active.svg";
      } else {
        return "/media/nav/alert.svg";
      }
    } else if (name === 'Customers') {
      if (active) {
        return "/media/nav/agent_active.svg";
      } else {
        return "/media/nav/agent.svg";
      }
    } else if (name === 'Settings') {
      if (active) {
        return "/media/nav/settings_active.svg";
      } else {
        return "/media/nav/settings.svg";
      }
    } else if (name === 'Billing') {
      if (active) {
        return "/media/nav/billing_active.svg";
      } else {
        return "/media/nav/billing.svg";
      }
    }
    return "/media/nav/dashboard.svg";
  }

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {
          links && links.map((ele, i) => {
            return (
              <li key={i}
                className={`menu-item`}
                style={{
                  marginBottom: 15,
                  marginTop: i === 0 ? 10 : 0,

                }}
              >
                {ele.name === 'API Documentation' ?
                  <a className="menu-link" style={{
                    backgroundColor: 'transparent'
                  }} href="https://documenter.getpostman.com/view/17334977/UVypzxYT#6863e612-eeef-44fb-aa42-7844a4d3c607" target="_blank" rel="noopener noreferrer">
                    <span className="svg-icon menu-icon">
                      {/* https://sety.readme.io <SVG src={toAbsoluteUrl(getRoute(ele.name, returnAct(ele.link)))}/> */}
                      <img src="/media/nav/share.png" alt="share" width={"17"} height={"17"} />
                    </span>
                    <span className="menu-text" style={{
                      color: returnAct(ele.link) ? '#5341C4' : '#393939',
                      fontWeight: 600,
                    }}>{ele.name}</span>
                  </a> 
                  :
                  ele.name === 'Reports' ?
                  <a className="menu-link" style={{
                    backgroundColor: 'transparent'
                  }} href="/reports">
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl(getRoute(ele.name, returnAct(ele.link)))} />
                    </span>
                    <span className="menu-text" style={{
                      color: returnAct(ele.link) ? '#5341C4' : '#393939',
                      fontWeight: 600,
                    }}>{ele.name}</span>
                  </a>
                  :
                  <NavLink className="menu-link" style={{
                    backgroundColor: 'transparent'
                  }} to={ele.link}>
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl(getRoute(ele.name, returnAct(ele.link)))} />
                    </span>
                    <span className="menu-text" style={{
                      color: returnAct(ele.link) ? '#5341C4' : '#393939',
                      fontWeight: 600,
                    }}>{ele.name}</span>
                  </NavLink>
                }
              </li>
            )
          })
        }
      </ul>
      {/* end::Menu Nav */}
      {/* API DOCUMEN */}
    </>
  );
}
