import React, { useMemo } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useCustomersUIContext } from "../UIContext";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';


const prepareFilter = (queryParams, values) => {
  const { status, type, range, reporter, searchText } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  // Filter by status
  filter.status = status;// !== "" ? +status : undefined;
  // Filter by type
  filter.class = type;// ? +type : undefined;
  // Filter by all fields
  filter.lastName = searchText;
  filter.range = range;
  filter.reporter = reporter;
  if (searchText) {
    filter.firstName = searchText;
    filter.email = searchText;
    filter.ipAddress = searchText;
  }
  newQueryParams.filter = filter;
  return newQueryParams;
};

export function CustomersFilter({ listLoading, incident_class, exportToCsv, entities }) {
  // Customers UI Context
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      queryParams: customersUIContext.queryParams,
      setQueryParams: customersUIContext.setQueryParams,
    };
  }, [customersUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    // console.log("paramsoooo", values)
    const newQueryParams = prepareFilter(customersUIProps.queryParams, values);
    if (!isEqual(newQueryParams, customersUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      customersUIProps.setQueryParams(newQueryParams);
    }
  };


  function handleEvent(event, picker) {
    // console.log(picker.startDate);
  }
  
  function handleCallback(start, end, label) {
    // console.log(start, end, label);
  }


  return (
    <div style = {{
        display: 'flex',
        justifyContent: 'space-between'
    }}>
      <div style={{flex: 1}}>
        <Formik
          initialValues={{
            status: "", // values => All=""/Susspended=0/Active=1/Pending=2
            type: "", // values => All=""/Business=0/Individual=1
            searchText: "",
          }}
          onSubmit={(values) => {
            applyFilter(values);
          }}
        >
          {({
            values,
            handleSubmit,
            handleBlur,
            handleChange,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit} className="form form-label-right">
              <div className="form-group row">
                <div className="col-lg-2">
                  <select
                    className="form-control"
                    name="status"
                    placeholder="Filter by Status"
                    // TODO: Change this code
                    onChange={(e) => {
                      setFieldValue("status", e.target.value);
                      handleSubmit();
                    }}
                    onBlur={handleBlur}
                    value={values.status}
                  >
                    <option value="">All</option>
                    <option value="pending">Pending</option>
                    <option value="started">Started</option>
                    <option value="completed">Completed</option>
                  </select>
                  <small className="form-text text-muted">
                    <b>Filter</b> by Status
                  </small>
                </div>
                <div className="col-lg-2">
                  <select
                    className="form-control"
                    placeholder="Filter by Type"
                    name="type"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("type", e.target.value);
                      handleSubmit();
                    }}
                    value={values.type}
                  >
                    <option value="">All</option>
                    {
                      incident_class && incident_class.map((element, i) => {
                        return (
                          <option key = {i} value = {element.name}>{element.name}</option>
                        )
                      })
                    }
                  </select>
                  <small className="form-text text-muted">
                    <b>Filter</b> by Incident Class
                  </small>
                </div>

                <div className="col-lg-2">
                  <select
                    className="form-control"
                    name="reporter"
                    placeholder="Filter by Reporter"
                    // TODO: Change this code
                    onChange={(e) => {
                      setFieldValue("reporter", e.target.value);
                      handleSubmit();
                    }}
                    onBlur={handleBlur}
                    value={values.reportet}
                  >
                    <option value="">All</option>
                    <option value="rider">Rider</option>
                    <option value="driver">Driver</option>
                  </select>
                  <small className="form-text text-muted">
                    <b>Filter</b> by Reporter
                  </small>
                </div>


                <div className="col-lg-3">
                <DateRangePicker
                    // onEvent={handleEvent} onCallback={handleCallback} //endDate
                    onApply = {(a,b) => {
                      setFieldValue("range",{
                        startDate: b.startDate.format('YYYY-MM-DD'),
                        endDate: b.endDate.format('YYYY-MM-DD')
                      });
                      handleSubmit();
                    }}
                    initialSettings={{ startDate: '1/1/2022', endDate: new Date() }}
                  >
                  <input
                    type="text"
                    className="form-control"
                    // name="searchText"
                    placeholder="Date range"
                    // onBlur={handleBlur}
                    // value={values.searchText}
                    // onChange={(e) => {
                    //   setFieldValue("searchText", e.target.value);
                    //   handleSubmit();
                    // }}
                  />
                  </DateRangePicker>
                  <small className="form-text text-muted">
                    <b>Filter</b> by date
                  </small>
                </div>


                {/* <div className="col-lg-2">
                  <input
                    type="text"
                    className="form-control"
                    name="searchText"
                    placeholder="Search"
                    onBlur={handleBlur}
                    value={values.searchText}
                    onChange={(e) => {
                      setFieldValue("searchText", e.target.value);
                      handleSubmit();
                    }}
                  />
                  <small className="form-text text-muted">
                    <b>Search</b> in all fields
                  </small>
                </div> */}
              </div>
            </form>
          )}
        </Formik>
      </div>

      {entities &&
        <a href="javascript:;" onClick={() => exportToCsv()} class="btn btn-bg-white btn-light btn-hover-primary btn-icon mr-3 my-2 my-lg-0">
            <i class="flaticon-download-1 icon-md"></i>
        </a>}
    </div>
  );
}
