import React, {useMemo} from "react";
import {Link} from "react-router-dom";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import {useHtmlClassService} from "../../_core/MetronicLayout";
import {toAbsoluteUrl} from "../../../_helpers";

export function Brand(props) {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      brandClasses: uiService.getClasses("brand", true),
      asideSelfMinimizeToggle: objectPath.get(
          uiService.config,
          "aside.self.minimize.toggle"
      ),
      headerLogo: uiService.getLogo(),
      headerStickyLogo: uiService.getStickyLogo()
    };
  }, [uiService]);

  return (
    <>
      {/* begin::Brand */}
      <div
          className={`brand flex-column-auto ${layoutProps.brandClasses}`}
          id="kt_brand"
          style = {{
            backgroundColor: '#F0EDFF',
            // borderBottom: '2px solid #FFF'
          }}
      >
        {/* begin::Logo */}
        <Link to="" className="brand-logo">
          {/* <img alt="logo" src={layoutProps.headerLogo}/> */}
          {/* <SVG src={toAbsoluteUrl("/media/")}/> */}
          <img
              alt="Logo"
              className="max-h-70px"
              style = {{marginTop: 10}}
              src={toAbsoluteUrl("/media/logos/Logo.svg")}
          />
          {/* <div className = "text-light" style = {{
            fontWeight: '1000',
            fontSize: 18,
            textTransform: 'uppercase'
          }}>{props.organization ? props.organization.name : null}</div> */}
        </Link>
        {/* end::Logo */}

        {layoutProps.asideSelfMinimizeToggle && (
          <>
            {/* begin::Toggle */}
            <button className="brand-toggle btn btn-sm px-0" id="kt_aside_toggle">
              <span className="svg-icon svg-icon-xl">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-left.svg")}/>
              </span>
            </button>
            {/* end::Toolbar */}
            </>
        )}
      </div>
      {/* end::Brand */}
      </>
  );
}
