import React, { useState, useRef } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { createBusinessCustomer } from "../_redux/authCrud";
import { ToastContainer, toast } from 'react-toastify';
import { Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'

const initialValues = {
    first_name: "",
    last_name: "",
    tel: "",
    email: "",
    password: "",
    acceptTerms: false,
};
function InvitedCustomer(props) {
    const { intl } = props;
    const searchParams = props?.location?.search;
    const [gender, setGender] = useState('others');
    const parsedParams = Object.fromEntries(new URLSearchParams(searchParams));
    initialValues.email = parsedParams['email'];
    const [loading, setLoading] = useState(false);
    const [phone_err, setPhoneErr] = useState('');
    const phoneRegExp = /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/
    const phoneRef = useRef();
    const [phoneNumber, setPhoneNumber] = useState("");
    const [data, setData] = useState({});
  
    const InvitedCustomerSchema = Yup.object().shape({
        first_name: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        last_name: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        tel: Yup.string().matches(phoneRegExp, 'Enter valid phone number with country code'),
        email: Yup.string()
            .email("Wrong email format")
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        password: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        acceptTerms: Yup.bool().required(
            "You must accept the terms and conditions"
        ),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }
        return "";
    };

    const formik = useFormik({
        initialValues,
        validationSchema: InvitedCustomerSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            enableLoading();
            var body = {};
            if (!parsedParams.email || !parsedParams.token || !parsedParams.invitation_id) {
                disableLoading();
                return toast.error(`Error: Please revalidate this link from your email`);
            }
            if (!gender) {
                disableLoading();
                return toast.error(`Error: Please select a gender`);
            }
            body['first_name'] = values.first_name;
            body['last_name'] = values.last_name;
            body['email'] = values.email;
            body['phone_number'] = formatPhoneNumberIntl(`+${data?.dialCode}${phoneNumber}`).replace(/\s/g, ''); //values.tel;
            body['password'] = values.password;
            body['user_type'] = 'customer';
            body['country_code'] = `${data?.iso2.toUpperCase()}`; //'NG';
            body['country_phone_code'] = `+${data?.dialCode}`;
            body['gender'] = gender;
            const payload = {
                invitation_id: parsedParams.invitation_id,
                user_data: body,
                token: parsedParams.token,
            }
            createBusinessCustomer(payload)
                .then((response) => {
                    var data = response.data;
                    setSubmitting(false);
                    disableLoading();
                    toast.success('Success: Your account has been created.');
                    if (data.status == "Success") {
                        localStorage.setItem('userEmail', body.email);
                        setTimeout(() => {
                            props.history.push({pathname: '/auth/verify-customer-account'});
                        }, 200);
                    } else {
                        toast.warning('Please try again later. An error occured.');
                    }
                })
                .catch((error) => {
                    setSubmitting(false);
                    var message = error?.response?.data?.message;
                    toast.error(message || `Error: An error occured.`);
                    setStatus(`${message}`);
                    disableLoading();
                });
        },
    });

    const updateGender = (e) => {
        setGender(e.target.value);
    }

    return (
        <div className="login-form login-signin" style={{ display: "block" }}>
            <div className="text-center mb-10 mb-lg-20">
                <h3 className="font-size-h1">
                    <FormattedMessage id="AUTH.INVITEDCUSTOMER.TITLE" />
                </h3>
                <p className="text-muted font-weight-bold">
                    Enter your details to create a customer account
                </p>
            </div>

            <form
                id="kt_login_signin_form"
                className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                onSubmit={formik.handleSubmit}
            >
                {/* begin: Alert */}
                {formik.status && (
                    <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                        <div className="alert-text font-weight-bold">{formik.status}</div>
                    </div>
                )}
                {/* end: Alert */}

                {/* begin: Fullname */}
                <div className="form-group fv-plugins-icon-container">
                    <input
                        placeholder="Enter first name"
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                            "fullname"
                        )}`}
                        name="first_name"
                        {...formik.getFieldProps("first_name")}
                    />
                    {formik.touched.first_name && formik.errors.first_name ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.first_name}</div>
                        </div>
                    ) : null}
                </div>

                <div className="form-group fv-plugins-icon-container">
                    <input
                        placeholder="Enter last name"
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                            "fullname"
                        )}`}
                        name="last_name"
                        {...formik.getFieldProps("last_name")}
                    />
                    {formik.touched.last_name && formik.errors.last_name ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.last_name}</div>
                        </div>
                    ) : null}
                </div>
                {/* end: Fullname */}

                {/* begin: Email */}
                <div className="form-group fv-plugins-icon-container">
                    <input
                        readOnly
                        placeholder="invitedcustomer@mail.com"
                        type="email"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                            "email"
                        )}`}
                        name="email"
                        {...formik.getFieldProps("email")}
                    />
                    {formik.touched.email && formik.errors.email ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.email}</div>
                        </div>
                    ) : null}
                </div>
                {/* end: Email */}

                <div className="form-group fv-plugins-icon-container">
                    <IntlTelInput
                        ref = {phoneRef}
                        preferredCountries={['ng', 'za', "us", "gb", 'gh']}
                        inputClassName={`form-control form-control-solid h-auto py-5  ${getInputClasses(
                            "tel"
                        )}`}
                        style = {{
                            width: '100%'
                        }}
                        format = {true}
                        onPhoneNumberFocus = {(isValid, number, obj) => {
                            var num = `+${obj?.dialCode}${number}`;
                            var isValidR = isValidPhoneNumber(num)
                            // console.log(isValidR)
                            setPhoneErr(isValidR ? "" : "Enter a valid phone number")
                          }}
                          onPhoneNumberBlur= {(isValid, number, obj) => {
                            var num = `+${obj?.dialCode}${number}`;
                            var isValidR = isValidPhoneNumber(num)
                            // console.log(isValidR)
                            setPhoneErr(isValidR ? "" : "Enter a valid phone number")
                          }}
                          onPhoneNumberChange = {(isValid, number, obj) => {
                              var num = `+${obj?.dialCode}${number}`;
                              var isValidR = isValidPhoneNumber(num)
                              setPhoneErr(isValidR ? "" : "Enter a valid phone number")
                              setPhoneNumber(number)
                              setData(obj)
                          }}
                        containerClassName="intl-tel-input"
                    />
                    {phone_err ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{phone_err}</div>
                        </div>
                    ) : null}
                </div>

                {/* begin: gender */}
                <div className="form-group fv-plugins-icon-container">

                    <FormControl fullWidth>
                        <select className="form-control form-control-solid h-auto py-5 px-2" onChange={updateGender}>
                            <option value="">Gender</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="others">Others</option>
                        </select>
                    </FormControl>
                </div>

                {/* begin: Password */}
                <div className="form-group fv-plugins-icon-container">
                    <input
                        placeholder="Password"
                        type="password"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                            "password"
                        )}`}
                        name="password"
                        {...formik.getFieldProps("password")}
                    />
                    {formik.touched.password && formik.errors.password ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.password}</div>
                        </div>
                    ) : null}
                </div>
                {/* end: Password */}

                {/* begin: Terms and Conditions */}
                <div className="form-group">
                    <label className="checkbox">
                        <input
                            type="checkbox"
                            name="acceptTerms"
                            className="m-1"
                            {...formik.getFieldProps("acceptTerms")}
                        />
                        <a href="https://sety.io/terms" target="_blank" className="mr-1" rel="noopener noreferrer">
                            I agree the Terms & Conditions
                        </a>
                        <span />
                    </label>
                    {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.acceptTerms}</div>
                        </div>
                    ) : null}
                </div>
                {/* end: Terms and Conditions */}
                <div className="form-group d-flex flex-wrap flex-center">
                    <button
                        type="submit"
                        disabled={formik.isSubmitting || !formik.values.acceptTerms}
                        className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                    >
                        <span>Submit</span>
                        {loading && <span className="ml-3 spinner spinner-white"></span>}
                    </button>
                </div>
            </form>
            
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(InvitedCustomer));
