import React, {useEffect, useState} from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

import { shallowEqual, useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import Loader from "../../../../_metronic/layout/components/loader";
import TimeAgo from 'react-timeago'
import {  useDispatch } from "react-redux";
import * as actions from "../_redux/incidents/Actions";
import Moment from "moment";

const perfectScrollbarOptions = {
    wheelSpeed: 2,
    wheelPropagation: false,
};
export default function ViewList(props) {
    const dispatch = useDispatch();

 
    const [data, set_data] = useState([]);
   
    const { dataForView, loading, pending_danger } = useSelector(
      (state) => ({
        dataForView: state.alert.dataForView,
        pending_danger: state.alert.pending_danger,
        loading: state.alert.listLoading
      }),
      shallowEqual
    );


    function getStat(d) {
        if (d === 'pending') {
            return 'pending';
        } else {
            return `now ${d}`;
        }
    }

    function getName(n) {
        if (n === 'car_make') {
            return "Car Make";
        } else if (n === 'car_model') {
            return "Car Model";
        } else if (n === 'plate_number') {
            return "Plate Number";
        } else if (n === 'reporter') {
            return "User Type";
        } else if (n === 'car_color') {
            return "Car Color";
        }else if (n === 'more_info') {
            return "More Info"
        } else {
            return n;
        }
    }


    // const meta = JSON.parse(dataForView.meta_data);
    // console.log(dataForView)

  
    return (<>

      

                <div>
                {loading && !dataForView ? 
                <Loader data = {[1,2,3]} type = {"info"}/> 
                :
                !loading && !dataForView ?
                <div>
                <div className="d-flex flex-center text-center text-muted min-h-150px">
                    All caught up!
                    <br />
                    No data here.
                </div>
                <div className = {`text-center text-danger font-weight-bold`}>
                        <a
                        onClick = {() => props.action ? props.action() : console.log('hello')}
                    >{props.actionText ? props.actionText : 'Try Again'}</a></div>
                </div>
                :
                
                <div>
                <div style={{flexDirection: 'column', 
                borderBottom: '1px solid #F0EDFF',
                display: 'flex'}}>

                    <span
                        className="text-dark font-weight-bolder text-hover-primary mb-4 font-size-lg"
                    >
                        {`${dataForView.user.first_name} ${dataForView.user.last_name}`}
                    </span>

                    <span class="label label-lg label-inline font-weight-bold mb-7" 
                    style = {{
                        backgroundColor: '#FFE2E1',
                        width: 150
                    }}>{dataForView.category ? dataForView.category.name : "Danger"}</span>

                    <span
                        className="mb-7 font-size-sm"
                        style = {{color: '#555063'}}
                    >
                        {`${dataForView.addresses[0].formatted_address}`}
                    </span>

                    <span
                        className="mb-7 font-size-sm"
                        style = {{color: '#555063'}}
                    >
                        <SVG className = "mr-2" style = {{width: 14, height: 14, marginRight: 5}} src={toAbsoluteUrl('/media/nav/clock.svg')}/>
                        {Moment(dataForView.created_at).format('LLLL')}
                    </span>

                    {props.distance && props.eta ?
                    <span
                    className="text-primary font-weight-bold mb-5 font-size-sm"> 
                        {`Agent is ${props.distance} (${props.eta})`}</span>
                        : null }

                </div>

                {dataForView.meta_data &&
                    <div className=" mt-5 mb-10" style={{
                        // flexDirection: 'column', 
                    borderBottom: '1px solid #F0EDFF',
                    }}>
                    <span
                        className="text-dark font-weight-bolder mb-10"
                        style = {{color: '#555063'}}
                    >
                    Vehicle Info
                    </span>

                    {
                        dataForView.meta_data && 
                        Object.entries(dataForView.meta_data).map((d, i) => {
                            return (
                                <div
                                className = {`${i === 0 ? 'mt-5' : 'mt-2'} mb-5`}
                                style = {{
                                    alignItems:'center',
                                    justifyContent: 'space-between',
                                    display: 'flex',
                                }}>
                                    <span
                                        className="font-size-sm"
                                        style = {{color: '#555063', flex: 1}}
                                    >
                                        {getName(d[0])}
                                    </span>

                                    <span
                                        className="font-size-sm"
                                        style = {{color: '#555063', flex: 1}}
                                    >
                                    {d[1]}
                                    </span>
                                </div>
                            )
                        })
                    }
                    

                    </div>
                }




                <div className="mt-5" style={{
                    // flexDirection: 'column', 
                borderBottom: '1px solid #F0EDFF',
                alignItems:'center',
                justifyContent: 'space-between',
                display: 'flex'}}>

                
                    <span
                        className=" mb-7 font-size-sm"
                        style = {{color: '#555063'}}
                    >
                        Incident status
                    </span>

                    <span class="label label-lg label-inline font-weight-bold mb-7" 
                    style = {{
                        backgroundColor: '#FCDF81',
                        textTransform: 'uppercase',
                        width: 150
                    }}>{getStat(dataForView.status)}</span>


                </div>
                
                
                </div>
                
                    }
                </div>
                

             


         


</>);
}
