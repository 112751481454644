// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React from "react";
import {
  CustomerTypeCssClasses,
  // CustomerTypeTitles,
} from "../../UIHelpers";

function getIndex(stat) {
  if (stat === 'L3/L4') {
    return 0;
  } else if (stat === 'L1/L2') {
    return 3;
  } else if (stat === 'L5') {
    return 1;
  } else {
    return 1;
  }
}


function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function TypeColumnFormatter(cellContent, row) {
  return (
    <>
      <span
        className={`label label-dot label-${
          CustomerTypeCssClasses[getIndex(cellContent)]
        } mr-2`}
      ></span>
      &nbsp;
      <span className={`font-bold font-${CustomerTypeCssClasses[cellContent]}`}>
        {/* {CustomerTypeTitles[getIndex(row.type)]} */}
        {capitalizeFirstLetter(cellContent ? cellContent : 'NULL')}
      </span>
    </>
  );
}
