import axios from "axios";
import config from "../../../../../config";

export const URL = config.URL;

// CREATE =>  POST: add a new data to the server
export function addNewData(type, data, queryParams) {
  if (type === 'api') {
    return axios.post(`${URL}businesses/api-key/generate`, data);
  } else if (type === 'revoke') {
    return axios.post(`${URL}businesses/api-key/revoke`, data);
  } else if (type === 'resend') {
    return axios.post(`${URL}businesses/${queryParams}/resend-invites`, data);
  }
  return axios.post(URL, { data });
}

// READ
export function getAllDatas() {
  return axios.get(URL);
}

export function getSataById(dataId) {
  return axios.get(`${URL}/${dataId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findDatas(type, queryParams) {
  if (type === 'dashboard_data') {
    return axios.get(`${URL}businesses/${queryParams}/dashboard-data`);
  } else if (type === 'users') {
    return axios.get(`${URL}businesses/${queryParams}/admin`);
  } else if (type === 'api') {
    return axios.get(`${URL}businesses/${queryParams}/api-key`);
  } else if (type === 'pending') {
    return axios.get(`${URL}businesses/${queryParams}/pending-invites`);
  } 
}

// UPDATE => PUT: update the data on the server
export function updateData(data) {
  return axios.put(`${URL}/${data.id}`, { data });
}

// UPDATE Status
export function updateStatusForDatas(ids, status) {
  return axios.post(`${URL}/updateStatusFordatas`, {
    ids,
    status
  });
}

// DELETE => delete the data from the server
export function deleteData(dataId) {
  return axios.delete(`${URL}/${dataId}`);
}

// DELETE datas by ids
export function deleteDatas(ids, type) {
  if (type === 'invite') {
    return axios.delete(`${URL}businesses/invites/${ids}`);
  }
  return axios.post(`${URL}/deletedatas`, { ids });
}
