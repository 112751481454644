/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
// import { property } from "lodash-es";
import React from "react";
import ContentLoader from 'react-content-loader'

export default function Loader(props) {
    const count = props.data ? props.data : [1,2,3,4,5];
    const MyLoader = () =>  <ContentLoader viewBox="0 0 100% 70" height={70} width={"100%"}>
        <rect x="0" y="0" rx="5" ry="5" width="100%" height="70" />
    </ContentLoader>


    const ListLoader = () =>  
    <ContentLoader viewBox="0 0 100% 50" height={80} width={"100%"}>
        <rect x="0" y="0" rx="5" ry="5" width="100%" height="40" />
        <rect x="0" y="25" rx="5" ry="5" width="100%" height="40" />
    </ContentLoader>

    function getLoader(d) {
        if (d === 'info') {
            return <ListLoader/>
        }
        return <MyLoader/>;
    }

    return (
    <>
       {
           count.map((ele, i) => {
               return (
               <div key = {i}

                    // className = "row mt-5" 
                    >
                        {getLoader(props.type ? props.type : 'null')}
                    </div>)
           })
       }
    </>
    );
}


