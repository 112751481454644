import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken, request_partner } from "./authCrud";
// import history from '../../../history';

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  OrgLoaded: "REQUEST ORG",
  Error: "Set Error",
  RESET_TOKEN: "[RESET] TOKEN",

};

const initialAuthState = {
  user: undefined,
  organization: undefined,
  authToken: undefined,
  error: null,
};

// function forwardTo(location) {
//   history.push(location);
// }

export const reducer = persistReducer(
  { storage, key: "v706-demo3-auth", whitelist: ["user", "authToken", "organization"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }
      case actionTypes.RESET_TOKEN: {
        const { authToken } = action.payload;

        return { ...state, authToken };
      }
      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.OrgLoaded: {
        const { organization } = action.payload;
        return { ...state, organization };
      }

    
      case actionTypes.Error: {
        const { error } = action.payload;
        return { ...state, error };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: authToken => ({ type: actionTypes.Login, payload: { authToken } }),
  register: authToken => ({
    type: actionTypes.Register,
    payload: { authToken }
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: user => ({ type: actionTypes.UserRequested, payload: { user } }),
  fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } }),
  fulfillOrg: data => ({ type: actionTypes.OrgLoaded, payload: {organization: data}}),
  fulfillToken: authToken => ({ type: actionTypes.RESET_TOKEN, payload: { authToken:  authToken} }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    // console.log("Params", params)
    const { data } = yield getUserByToken();

    // console.log(org); return;ç
    if (data.data.type === 'business') {
      yield put(actions.fulfillUser(data.data));
    }


    try {
      const org = yield request_partner(data.data.user_id);
      // console.log(org)
      yield put(actions.fulfillOrg(org.data.data));
    } catch(e) {
      // yield put(actions.fulfillOrg(null))
      // console.log("here!!!")
      // yield call(forwardTo, '/auth/business/setup');
      window.location = '/auth/business/setup';
    } 
    

  });
} 
 