import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { create_business, request_partner } from "../_redux/authCrud";
import config from "../../../../config";
import { shallowEqual, useSelector } from "react-redux";


const initialValues = {
  business_name: "",
  industry: "",
  employees: "",
  country: "",
  address: ""
};

function Setup(props) {
  
  const { user } = useSelector(
    (state) => ({
      user: state.auth.user
    }),
    shallowEqual
);

  const { intl } = props;
  const [loading, setLoading] = useState(false);
  
  const BusinessSchema = Yup.object().shape({
    business_name: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    industry: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    employees: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    country: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    address: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    )
    
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: BusinessSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      // enableLoading();
      console.log('submitted');
      console.log('values: ', values, user);
      var body = {};
      body['name'] = values.business_name;
      body['industry'] = values.industry;
      body['employees'] = values.employees;
      body['country'] = values.country;
      body['address'] = values.address;
      body['user_id'] = user.user_id;
      create_business(body)
        .then((response) => {
          var data = response.data;
          console.log(data)
          setSubmitting(false);
          disableLoading();
          if (data.status === 'Success') {
              request_partner().then((response2) => {
                var data2 = response2.data;
                // console.log(data2)
                if (data2.status === 'Success') {
                  props.fulfillOrg(data2.data);
                }
              })

          } else {
            setStatus(data.error)
          }
        })
        .catch((error) => {
          setSubmitting(false);
          var message = error.response.data.message;
          setStatus(`${message}`)
          disableLoading();
        });
    },
  });

  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          Setup your business
        </h3>
        <p className="text-muted font-weight-bold">
          Enter your details below
        </p>
      </div>

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}

        {/* begin: Fullname */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Business Name"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "business_name"
            )}`}
            name="business_name"
            {...formik.getFieldProps("business_name")}
          />
          {formik.touched.business_name && formik.errors.business_name ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.business_name}</div>
            </div>
          ) : null}
        </div>

        <div className="form-group fv-plugins-icon-container">
          <select
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "fullname"
            )}`}
            name="industry"
            {...formik.getFieldProps("industry")}
          >
          <option>Selecy Industry</option>
          {
            config.industries.map((ele, i) => {
              return (
                <option key = {i} value={ele}>{ele}</option>
              )
            })
          }
          </select>
          
          {formik.touched.industry && formik.errors.industry ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.industry}</div>
            </div>
          ) : null}
        </div>

        <div className="form-group fv-plugins-icon-container">
          <select
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "fullname"
            )}`}
            name="employees"
            {...formik.getFieldProps("employees")}
          >
          <option value="">No of Employees</option>
          <option value="Less than 10">Less than 10</option>
          <option value="11-25">11-25</option>
          <option value="26-50">26-50</option>
          <option value="51+">51+</option>
          </select>
          
          {formik.touched.employees && formik.errors.employees ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.employees}</div>
            </div>
          ) : null}
        </div>


        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Company Address"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "address"
            )}`}
            name="address"
            {...formik.getFieldProps("address")}
          />
          {formik.touched.address && formik.errors.address ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.address}</div>
            </div>
          ) : null}
        </div>
        
        <div className="form-group fv-plugins-icon-container">
          <select
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "country"
            )}`}
            name="country"
            {...formik.getFieldProps("country")}
          >
            <option value="">Country</option>
            <option value="nigeria">Nigeria</option>
          </select>
          
          {formik.touched.country && formik.errors.country ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.country}</div>
            </div>
          ) : null}
        </div>



       
        {/* end: Terms and Conditions */}
        <div className="form-group d-flex flex-wrap flex-center">
          <button
            type="submit"
            disabled={formik.isSubmitting}
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>Submit</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>

          
        </div>
      </form>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Setup));
